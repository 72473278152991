.organizations-page-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.organizations-tag {
    display: flex;
    color: var(--primary-color);
    max-width: 1300px;
    flex-direction: column;
    padding: 20px;
    margin: 50px auto;
    width: 100%;
}


.organizations-box-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f1f5fb;
    padding: 2rem;
}

.organizations-box {
    display: flex;
    width: 100%;
    min-height: 300px;
    flex-direction: column;
    background-color: #f1f5fb;
    margin: 0 auto;
    margin-bottom: 1rem;
    padding: 2rem;
}

.organization-container {
    display: flex;
    gap: 1rem;
    width: 100%;
    border-radius: 10px;
    color: black;
    flex-wrap: wrap;
}

.organization-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 200px;
    justify-content: center;
    padding: 16px;
    border: 1px var(--primary-color) solid;
    background-color: #FFFFFF;
    border-radius: 20px;
    /*max-width:900px;*/
    justify-content: center;
    margin: auto;
    cursor: pointer;
}

.organization-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.organizations-title{
    font-size: 27px;
    margin-bottom: 1rem;
}

.organizations-toolbar-btn{
    padding: 10px;
    background-color: var(--primary-color);
    border-radius: 5px;
    font-size: 15px;
    color: #fff;
    border: none;
    width: 170px;
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 1rem;

    &:hover {
        background-color: var(--primary-color-hover);
    }
}

.organization-modal-form-container {
    display: flex;
    flex-direction: column;
    min-height: 400px;
}

.organization-form, .sub-organization-form{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    width: 800px;
}

.organization-label{
    width: 100%;
    margin-bottom: 10px;
}

.organization-label > p {
    font-size: 14px;
    color: gray;
    margin-top: 5px;
}

.sub-organization-form {
    margin-top: 2rem;
}

/* .organizations-create-btn{
    background-color: white;
    border: 3px solid var(--primary-color);
    color: var(--primary-color);
    padding: 7.6px 5px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 10px;
    min-width: 88px;
    &:disabled{
        background-color: rgb(213, 213, 213);
        cursor: auto;
        color: rgb(83, 83, 83);
        border: none;
        padding: 10px 0;
    }
} */
.organization-card-info {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.organization-card >p {
    font-size: 23px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.organization-card-info>span {
    font-size: 15px;
    color: var(--primary-color);
}

.organization-card-container {
    padding: 16px;
    column-gap: 3rem;
    row-gap: 1rem;
    width: 340px;
    position: relative;
    height: 200px;
    border-top: 2px var(--primary-color) solid;
    background-color: #FFFFFF;
    border-radius: 7px;
    /*max-width:900px;*/
    justify-content: center;
    margin: auto;
    cursor: pointer;
}

.organization-card-container:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.organizations-tabs {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
}

.organizations-tabs button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: transparent;
}

.organizations-tabs button:hover {
    color: var(--primary-color-hover);
    border-bottom: 1px solid var(--primary-color-hover);
}

.organizations-tabs button.active {
    color: var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
}

.searched-organizations {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    max-height: 400px;
    width: 100%;
    overflow-y: auto;
}

.searched-organization {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.searched-organization:hover {
    background-color: #e0e0e0;
}

.searched-organization p {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.searched-organization.selected {
    border: 1px solid var(--primary-color);
    background-color: #fff;

    > p {
        color: var(--primary-color);
    }
}

.searched-organizations {
    overflow-y: scroll;
    height: 200px;
}

.searched-organizations::-webkit-scrollbar {
    width: 10px;
}

.searched-organizations::-webkit-scrollbar-thumb {
    background: #888;
}

.searched-organizations::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.searched-organizations {
    scrollbar-width: thin;
    scrollbar-color: #888 #f0f0f0;
}

.searche-result-container {
    width: 60px;
}