.organization-users-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.header-container1 {
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;

}

.header-container2>h3 {
    color: #B1092A;
    margin-right: 40px;
}

.header-container2 {
    display: flex;
    align-items: center;
}

.header-container-btn {
    border-radius: 5px;
    padding: 10px 15px;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #B1092A;
    cursor: pointer;
    font-weight: 700;
    color: white;
    text-decoration: none;
    align-items: center;
}

.add-logo {
    margin-right: 5px;
    cursor: pointer;
}

.card-container {
    display: flex;
    width: 100%;
    border-radius: 10px;
    color: black;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 10px;
}