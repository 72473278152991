
.login-container {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    padding: 1rem 4rem !important;
}

.login-content-container {
    display: flex;
    /* justify-content: space-between; */
    margin: 0 auto;
    /* flex-direction: column; */
    width: 100%;
    max-width: 1200px;
}

.login-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* max-width: 550px; */
    max-width: 420px;
    height: 100%;
    /* padding: 10px 0; */
}

.form-title {
    width: 100%;
    max-height: 100px;
    margin-top: 2rem;
    padding-bottom: 15px;
    border-bottom: 1px gray solid;
}

.form-title > h2 {
    font-size: 28px;
    font-weight: 400;
}

.form-container {
    width: 100%;
    padding-bottom: 3rem;
    border-bottom: 1px gray solid;
}

.form-container > form {
    width: 100%;
    padding: 15px 0;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* .form-container input {
    padding: 15px;
    background-color: #e5e5e5;
    border: none;
    border-bottom: 1px gray solid;
    font-size: 20px;
} */

.remember-me-container {
    display: flex;
    align-items: center;
}
.remember-me{
    font-size: 16px;
    font-weight: 400;
    margin-right: 10px;
}

.check-box {
    margin-right: 5px;
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.option-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 2rem;
}

.reset-password-container {
    margin-top: 1rem;
    font-weight: 300;
}

.reset-password-container > a {
    color: black;
}

.image-container {
    /* flex-grow: 1; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

.image-container > img {
    width: 100%;
    height: 100%;

    max-width: 600px;
    max-height: 350px;
}


@media (max-width: 900px) {
    .login-container {
        padding: 1rem !important;
    }
    .image-container {
        display: none;
    }
}