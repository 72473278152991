
.reset-password-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 4rem !important;
}

.login-content-container {
    display: flex;
    /* justify-content: space-between; */
    margin: 0 auto;
    /* flex-direction: column; */
    width: 100%;
    max-width: 1200px;
}

.reset-password-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* max-width: 550px; */
    max-width: 420px;
    height: 100%;
    padding: 10px 0;
}

.form-title {
    width: 100%;
    max-height: 100px;
    margin-top: 2rem;
    padding-bottom: 15px;
    border-bottom: 1px gray solid;
}

.form-title > h2 {
    font-size: 28px;
    font-weight: 400;
}

.reset-form-container {
    width: 100%;
}

.reset-form-container > form {
    width: 100%;
    padding: 15px 0;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.reset-form-container > form > button {
    margin-top: 3rem;
}