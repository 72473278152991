.email-extraction-dashboard-container {
    flex-grow: 1;
    display: flex;
    /* flex-direction: column; */
}

.email-extraction-dashboard-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px 10px;
}

.email-extraction-demo-insturctions {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 10px;
}

.demo-insturctions__title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--primary-color);
    border-radius: 30px;
    padding: 10px;
    color: #fff;
    text-align: center;
}

.demo-insturctions__text {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    gap: 20px;
}

.demo-insturctions__text > span {
    text-align: justify;
}

.email-extraction-demo-setup {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    flex-grow: 1;
    align-items: center;
    /* width: 100%; */
    /* height: 100%; */
    max-height: 600px;
    overflow: scroll;
    padding: 10px;
}

.email-extraction-demo-setup__button-container,
.hotel-info-form-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 10px;
    margin-top: auto;
    margin-bottom: 10px;

}

.hotel-info-form-buttons {
    padding-right: 10px;
}

.email-extraction-official-insturctions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 40px;
}

.email-extraction-official-insturctions > img {
    width: 80%;
    height: auto;
    margin: -30px;
}

.email-extraction-official-insturctions > p {
    text-align: center;
    width: 70%;
}

.hotel-info-form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    gap: 10px;
    font-size: 0.8em;
    width: 800px;
    margin: 0 auto;
}

.hotel-info-title-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* padding: 0 1rem; */
}

.hotel-info-title-form > h3 {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    margin: 5px 0;
    padding-bottom: 5px;
    border-bottom: 1px gray solid;
    width: 100%;
}

.hotel-info-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.hotel-info-form > form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.email-extraction-multi-step-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    flex-grow: 1;
}

.outlook-auth-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    align-self: center;
    margin-top: 70px;
    width: 800px;
}

.outlook-auth-card h3 {
    color: #333;
    margin-bottom: 10px;
}

.outlook-auth-card p {
    color: #666;
    margin-bottom: 20px;
}

.outlook-auth-button {
    background-color: #0078d4;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 16px;
    margin: 4px auto;
    transition-duration: 0.4s;
}

.outlook-auth-button:hover {
    background-color: #004c87;
    color: white;
}

.outlook-logo {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    margin-bottom: -4px;
}

.progress-bar {
    width: 79%;
    align-self: center;
}

.email-sample-inbox-container {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    flex-grow: 1;
}

.email-inbox-listing-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    overflow: scroll;
    border: 1px solid black;
}

.email-inbox-row {
    display: flex;
    width: 100%;
    padding: 10px;
}