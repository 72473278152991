.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    background-color: #fff;
    position: relative;
}

.header-icon-container {
    display: flex;
    width: auto;
    height: 30px;
    max-width: 133px;
    cursor: pointer;
}

.header-icon-container > img {
    width: 100%;
    height: 100%;
}

.nav-container {
    display: flex;
    align-items: center;
    gap: 3rem;
    color: var(--primary-color);
    max-width: 900px;
}


.burger-icon {
    display: none;
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .nav-container {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 70px;
      right: 0;
      width: 250px;
      background-color: #F1F1F1;
      gap: 2rem;
      padding: 10px;
    }
  
    .nav-container.show-menu {
      display: flex;
    }
  
    .burger-icon {
      display: block;
    }
}

.nav-container>span,
.profile-container>svg {
    cursor: pointer;
}

.nav-container>span:hover,
.profile-container>svg:hover {
    color: var(--primary-color-hover);
}

.nav-container>span > a {
    text-decoration: none;
    color: var(--primary-color);
}

.profile-modal-container {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh; 
    min-height: 600px;
    width: 300px;
    background-color: #fff;
    padding: 10px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    z-index: 1000;
    overflow-y: auto; 
    transition: all 250ms ease;
    transform: translateX(550px); 
    display: flex;
    flex-direction: column;

}

.profile-modal-container.visible-profile-modal {
    transform: translateX(0); 
}

.user-card-info-container {
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    gap: 5px;
    padding-bottom: 10px;
    border-bottom: 1px #6e6e70 solid;
}

.user-name-container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
}

.user-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5px;
    padding: 5px;
    max-width: 310px;
    overflow: hidden;
    color: black;
}

.user-info>div {
    font-weight: 700;
}

.user-info > .user-email-container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    font-size: 14px;
    color: gray;
}

.close-profile-icon {
    display: flex;
    padding: 5px;
    margin-bottom: 5px;
    cursor: pointer;

}

.close-profile-icon:hover{
    background-color: lightgray;
    border-radius: 5px;
}


.logout-container {
    width: 100%;
    display: flex;
    margin-bottom: auto;
    flex-direction: column;
    gap: 5px;
    margin-top: auto;
    margin-bottom: 10px;
}

.logout-container>button {
    padding: 5px;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-color);
    gap: 5px;
}

.logout-container>button:hover {
    color: var(--primary-color-hover);
}

.logout-container>a {
    padding: 5px;
    display: flex;
    border: none;
    /* background-color: transparent; */
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    justify-content: flex-start;
    align-items: center;
    color: black;
    text-decoration: none;
    gap: 5px;
}

.logout-container>a>svg {
    color: var(--primary-color);
}

.footer-container {
    background-color: #fff;
    color: var(--primary-color);
    font-size: 20px;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
}

.besca-text {
    cursor: pointer;
}

.besca-text:hover {
    cursor: pointer;
    color: #d6d1d1;
}

.items-container {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    margin-top: 3rem;
    gap: 1rem;
}

.prodcuts-container,
.supports-container,
.Company-container,
.partners-container,
.Legal-container {
    display: flex;
    flex-direction: column;
    /* margin-left: 140px; */
    font-size: 18px;
}

.prodcuts-container>h3,
.supports-container>h3,
.Company-container>h3,
.partners-container>h3,
.Legal-container>h3 {
    margin-bottom: 12px;
}

.prodcuts-container>span,
.supports-container>span,
.Company-container>span,
.partners-container>span,
.Legal-container>span {
    color: #d3d3d4;
    font-size: 14px;
    cursor: pointer;
    margin: 5px 0;
}

.prodcuts-container>span:hover,
.supports-container>span:hover,
.Company-container>span:hover,
.partners-container>span:hover,
.Legal-container>span:hover {
    color: #8a8a8b;

}

.options-container {
    border-bottom: 1px rgb(134, 132, 132) solid;
    width: 100%;
}

.options-container>a {
    padding: 10px;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-color);
    text-decoration: none;
}

.manage-account-container>button {
    padding: 5px;
    border: none;
    border: 2px white solid;
    background-color: var(--primary-color);
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
}

.manage-account-container>button:hover {
    background-color: var(--primary-color-hover);
}

.terms-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    padding: 5px;
    gap: 5px;
    border-top: 1px rgb(134, 132, 132) solid;
    gap: 5px;
}

.terms-container>a {
    border: none;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    color: #505052;
    gap: 5px;
    text-decoration: none;
}

.profile-name-container {
    width: 100%;
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 8px;
}

.profile-name-container>a {
    padding: 5px;
    display: flex;
    border: none;
    /* background-color: transparent; */
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    justify-content: flex-start;
    align-items: center;
    color: black;
    text-decoration: none;
    gap: 5px;
}

.profile-name-container>svg {
    color: var(--primary-color);
}

.profile-name-container>svg:hover {
    color: var(--primary-color-hover);
    font-weight: 700;
}

.profile-name-container :hover {
    color: var(--primary-color-hover);
    font-weight: 700;
}

.footer-content-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    font-weight: 500;
    /* padding: 10px; */
}

.bex-assistants-nav-container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px rgb(134, 132, 132) solid;
    margin-top: 5px;

}

.bex-assistants-nav-container > span {
    color: gray;
}

.profile-container {
    position: relative;
}

.profile-container.modal-open::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}
