/* Login Styles */
.secure-login-container{
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  padding: 1rem 4rem !important;
}

.secure-login-content-container{
  display: flex;
  align-items: center;
  justify-content: center;
  /* justify-content: space-between; */
  margin: 0 auto;
  /* flex-direction: column; */
  width: 100%;
  max-width: 1200px;
}
.secure-login-form-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* max-width: 550px; */
  max-width: 420px;
  height: 100%;
  padding: 10px 0;
  gap: 16px;
}
.secure-login-form-form{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.secure-login-form-title {
  width: 100%;
  max-height: 100px;
  margin-top: 2rem;
  padding-bottom: 15px;
  border-bottom: 1px gray solid;
}

.secure-login-form-title > h2 {
  font-size: 28px;
  font-weight: 400;
}
