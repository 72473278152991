.admin-organization-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.admin-organization-header {
    display: flex;
    width: 100%;
    padding: 10px;
}

.manage-organization-container {
    display: flex;
    width: 100%;
    max-width: 900px;
}

.manage-organization-container__assistants {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.manage-organization-assistants {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.organization-assistant-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 10px;
}

.organization-assistant-container__name {
    display: flex;
}

.organization-assistant-container__label {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}

.assistant-switch-button {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 0 5px; */
    width: 40px;
}