.dashboard-container {
    flex-grow: 1;
    display: flex;
    padding: 0 !important;
    /* position: relative; */
}

.dashboard-background-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 650px;
    height: 460px;
    overflow: hidden;
}

.dashboard-background-image img {
    width: 650px;
    height: 480px;
}

.inner-dashboard {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    margin: 2rem auto;
}

.dashboard-title {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.dashboard-title > h4 {
    font-size: 30px;
    font-weight: 600;
}

.dashboard-title>h2 {
    font-size: 41px;
    margin: 10px 0;
    font-weight: 500;
}

.dashboard-title>span {
    font-size: 18px;
    margin-top: 10px;
}

.nav-card-container {
    display: flex;
    margin-top: 2rem;
    flex-wrap: wrap;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.option-bar-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 3rem;
    color: var(--primary-color);
}

.option-bar-container>button {
    display: flex;
    align-items: center;
    gap: 5px;
    color: white;
    background-color: #25D366;
    padding: 7px 10px;
    border: none;
    border-radius: 10px;
    font-size: 15px;
    cursor: pointer;
}

.option-bar-container>button:hover {
    background-color: #0bb549;
}

.option-bar-container>a {
    display: flex;
    color: var(--primary-color);
    border: none;
    font-size: 15px;
    text-decoration: none;
}

.option-bar-container>a:hover {
    color: var(--primary-color-hover);
}

.dashboard-card-container {
    margin-top: 40px;
    display: flex;
    gap: 4rem;
    flex-wrap: wrap;
    /* justify-content: space-around; */
}

.dashboard-card {
    display: flex;
    background-color: #94989E;
    width: 200px;
    height: 200px;

}

.dashboard-card > a {
    flex-grow: 1;
    text-decoration: none;
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    /* justify-content: space-between; */
    padding: 25px 25px 10px 25px;
    cursor: pointer;
    text-align: center;
}

.dashboard-card > a > svg {
    margin-top: 1rem;
    min-height: 35px;
}

.dashboard-card > a > span {
    margin-top: 1rem;
}

.dashboard-card__disable-assistant > a {
    color: #e5e5e5;
    /* cursor: not-allowed; */
}

.hashboard-outlet-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
}

.hashboard-breadcrumbs-container {
    display: flex;
    width: 100%;
    padding: 10px;
}

.dashboard-app-setup-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    margin: 15px auto 0 auto;
}

.dashboard-app-title {
    display: flex;
    margin: 15px 0;
}

.dashboard-app-title > h3 {
    font-weight: 600;
    font-size: 25px;
}

.dashboard-app-text {
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin: 15px 0;
}

.dashboard-app-text > p {
    font-size: 18px;
    /* font-weight: 200; */
    text-align: justify;
}

.dashboard-app-setup-card-container {
    display: flex;
    margin-top: 15px;
}

/* .organization-dropdown {
    align-self: flex-end;
    margin: 10px;
}

.organization-dropdown > span {
    font-size: 18px;
    font-weight: 600;
    margin-right: 10px;
} */

/*  */

/* .dropdown {
    align-self: flex-end;
    position: relative;
    background-color: #f1f1f1;
    border: none;
    color: black;
    padding: 10px;
    font-size: 16px;
    min-width: 200px;
    cursor: pointer;
    transition: 0.3s;
}

.dropdown:hover {
    background-color: #ddd;
}

.dropdown.open {
    background-color: var(--primary-color);
    color: white;
} */

/* .dropdown > span {
    font-size: 18px;
    font-weight: 500;
    margin-right: 8px;
} */

/* .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: #f1f1f1;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    cursor: pointer;
} */

.dropdown-menu div {
    color: black;
    padding: 12px 20px;
    text-decoration: none;
    display: block;
}

.dropdown-menu div:hover {
    background-color: #ddd;
}

.dropdown-menu .selected {
    color: var(--primary-color);
}

.dropdown-menu .selected::before {
    background-color: var(--primary-color);
}

.dropdown-menu .selected::before {
    height: 22px;
}

.dropdown-menu .selected::before {
    content: "";
    position: absolute;
    width: 4px;
    left: 0px;
    border-radius: 0px 4px 4px 0px;
    background-color: var(--primary-color);
    transition: background-color 150ms ease-in-out 0s;
}

.add-suborg-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    border: none;
    border-top: 2px solid #000000;
    cursor: pointer;
    transition: 0.3s;
}

.add-suborg-button:hover {
    background-color: var(--primary-color);
}

.add-suborg-button::before {
    content: "➕";
    margin-right: 5px;
}

.member-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.member-checkbox {
    margin-right: 10px;
}

.member-label {
    font-size: 16px;
    color: #333;
}

form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}