.secure-admins-permissions-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.secure-admins-permissions-header-label {
    margin-top: 10px;
    margin-bottom: 10px;
    color: red;
}

.hr-line {
    margin-top: 10px;
}

.secure-admins-permissions-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.edit-btn {
    background-color: #A6A6A6;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    border: none;
    border-radius: 2px;
    margin-right: 40px;
    cursor: pointer;
}

.secure-admins-permissions-add-btn {
    border-radius: 5px;
    padding: 10px 15px;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #B1092A;
    cursor: pointer;
    font-weight: 700;
    color: white;
    text-decoration: none;
    align-items: center;

}
    
.secure-admins-permissions-add-btn:disabled {
    cursor: not-allowed;
    background-color: gray;
}


.secure-admins-permissions-header-actions{
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.secure-admins-permissions-header-tbl-row {
    background-color: #FFFFFF;

}

.secure-admins-permissions-header-tr {
    margin-bottom: 10px;
    background-color: #FFFFFF;
    border-bottom: 5px solid #f2f2f2;
}

.secure-admins-permissions-create-btn{
    background-color: white;
    border: 3px solid var(--primary-color);
    color: var(--primary-color);
    padding: 7.6px 5px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 10px;
    min-width: 88px;
    &:disabled{
        background-color: rgb(213, 213, 213);
        cursor: auto;
        color: rgb(83, 83, 83);
        border: none;
        padding: 10px 0;
    }
}