.signup-container {
    /* 
    height: 100%;
    width: 100%; */
    display: flex;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signup-container > h2 {
    font-size: 30px;
    font-weight: 400;
    margin: 20px 0;
    padding-bottom: 15px;
    border-bottom: 1px gray solid;
    width: 100%;
    max-width: 430px;
}

.signup-form-container {
    width: 100%;
    max-width: 430px;
    /* height: 450px; */
    /* opacity: 0.8; */
}

.signup-form-container > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 10px;
}

/* .signup-form-container > form input {
    padding: 10px;
    border-radius: 15px;
    border: 1px black solid;
    font-size: 18px;
} */

.signup-form-container > form > button {
    margin-top: 20px;
    max-width: 100%;
    padding: 10px;
    font-size: 18px;
}

.signup-form-container > form > button:disabled {
    background-color: var(--primary-color-hover);
}

.signup-form-container > form > button:hover {
    background-color: var(--primary-color-hover);
}

.signup-form-container > form > button:disabled {
    background-color: var(--primary-color-hover);
    cursor: auto;
}

.login-link-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.input-container {
    margin-bottom: 20px;
    width: 100%;
}

.input-wrapper {
    position: relative;
}

.pass-input{
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.password-toggle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
    color: #666;
    display: flex;
    align-items: center;
}

