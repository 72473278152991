.legal-contract-dashboard-container {
    display: flex;
    flex-grow: 1;
    
}

.legal-contract-dashboard-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px 10px;
}

.legal-contract-demo-insturctions {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 10px;
}

.demo-insturctions__title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--primary-color);
    border-radius: 30px;
    padding: 10px;
    color: #fff;
    text-align: center;
}

.demo-insturctions__text {
    display: flex;
    flex-direction: column;
    overflow:auto;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    gap: 20px;
}

.demo-insturctions__text > span {
    text-align: justify;
}

.legal-contract-demo-setup {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    flex-grow: 1;
    align-items: center;
    /* width: 100%; */
    /* height: 100%; */
    max-height: 600px;
    overflow: scroll;
    padding: 10px;
}

.legal-contract-demo-setup__button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 10px;
    margin: 10px 0;

}

.legal-contract-demo-setup__button-container > button {
    padding: 10px 15px;
    border: 1px solid var(--primary-color);
    cursor: pointer;
    background-color: var(--primary-color);
    color: #fff;
}