.organization-settings-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.org-label {
    margin-top: 10px;
    margin-bottom: 10px;
    color: red;
}

.hr-line {
    margin-top: 10px;
}

.label-setting-field {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.label-setting-field > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.edit-btn {
    background-color: #A6A6A6;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    border: none;
    border-radius: 2px;
    margin-right: 40px;
    cursor: pointer;
}


.tbl-row {
    background-color: #FFFFFF;

}
/* 
tr {
    margin-bottom: 10px;
    background-color: #FFFFFF;
    border-bottom: 5px solid #f2f2f2;
} */

.organization-requests-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 5rem;
}