.ai-lab-markdown > * {
    all: revert;
    line-height: 1.6;
}

.ai-lab-markdown a {
    color: #3e3eff;
}
.ai-lab-markdown a:hover {
    text-decoration: underline;
}

/* .ai-lab-markdown strong {
    color: blue;
    display: inline;
    margin: 0;
    padding: 0;
    line-height: 0px;
} */

/* .ai-lab-markdown p {
    list-style-type: disc;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 4px;
} */

.ai-lab-markdown p {
    margin: 0 !important;
    padding: 0 !important;
}
.ai-lab-markdown li {
    margin: 0 !important;
    padding: 0 !important;
    white-space: normal;
}
.ai-lab-markdown ul {
    margin: 0 !important;
    list-style-type: disc;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.ai-lab-markdown ol {
    margin: 0 !important;
    list-style-type: decimal;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.ai-lab-markdown table {
    margin: 0 !important;
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    text-align: left;
    font-size: 0.8rem;
    /* border-radius: 8px; */
}

.ai-lab-markdown table th,
.ai-lab-markdown table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.ai-lab-markdown table th {
    background-color: #f4f4f4;
    font-weight: bold;
}
/* .ai-lab-markdown h1,
.ai-lab-markdown h2,
.ai-lab-markdown h3,
.ai-lab-markdown h4,
.ai-lab-markdown h5,
.ai-lab-markdown h6 {
    font-weight: 700;
    margin-bottom: 4px;
} */


/* Code */
.ai-lab-markdown pre div {
    border-radius: 8px;
}
