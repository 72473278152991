.custom-markdown a {
    color: #3e3eff;
}
.custom-markdown a:hover {
    text-decoration: underline;
}

.custom-markdown ul {
    list-style-type: disc;
}
.custom-markdown ol {
    list-style-type: decimal;
}

/* .custom-markdown table {
    border: 1px solid #707070;
    border-radius: 8px;
    overflow: hidden; 
    border-collapse: separate;
    border-spacing: 0;
}
.custom-markdown table th {
    background-color: #707070;
}
.custom-markdown table td, table th {
    border: 1px solid #707070;
}

.custom-markdown table tr td {
    border-right: 0;
}
.custom-markdown table tr:last-child td {
    border-bottom: 0;
}
.custom-markdown table tr td:first-child,
.custom-markdown table tr th:first-child {
    border-left: 0;
}
.custom-markdown table tr td{
    border-top: 0;
} */