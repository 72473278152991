/* Login Styles */
.secure-login-container{
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  padding: 1rem 4rem !important;
}

.secure-login-content-container{
  display: flex;
  align-items: center;
  justify-content: center;
  /* justify-content: space-between; */
  margin: 0 auto;
  /* flex-direction: column; */
  width: 100%;
  max-width: 1200px;
}
.secure-login-form-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* max-width: 550px; */
  max-width: 420px;
  height: 100%;
  padding: 10px 0;
  gap: 16px;
}
.secure-login-form-form{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.secure-login-form-title {
  width: 100%;
  max-height: 100px;
  margin-top: 2rem;
  padding-bottom: 15px;
  border-bottom: 1px gray solid;
}

.secure-login-form-title > h2 {
  font-size: 28px;
  font-weight: 400;
}

/* Profile-Modal */
/* .profile-modal{
  height: auto !important;
  border-radius: 0 !important;
  margin-top: 60px;
} */

.secure-home-logout-container {
  width: 100%;
  display: flex;
  margin-bottom: auto;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
}

.secure-home-logout-container>button {
  padding: 5px;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-color);
  color: black;
  gap: 5px;
}

/* Logout */
.secure-home-logout-container>button:hover {
  color: var(--primary-color-hover);
}

.secure-home-logout-container>a {
  padding: 5px;
  display: flex;
  border: none;
  /* background-color: transparent; */
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  justify-content: flex-start;
  align-items: center;
  color: black;
  text-decoration: none;
  gap: 5px;
}

.secure-home-logout-container>a>svg {
  color: var(--primary-color);
}


/* Dashboard (Secure Home) Styles */
.secure-home-container{
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  /* padding: 1rem 4rem !important; */
  padding-top: 2rem;
}

.secure-home-content-container{
  display: flex;
  flex-grow: 1;

}


.secure-home-container > .secure-home-content-container {
  display: flex;
  flex-grow: 1;

}

.secure-home-content-container > .secure-home-aside-menu {
  width: 250px;
  /* background-color: gray; */
  display: flex;
  padding: 10px;
  padding-top: 2rem;
}

.secure-home-nav-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  /* background-color: aliceblue; */
}

.secure-home-nav-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 5px;
}

.secure-home-nav-list > li {
  display: flex;
  /* justify-content: center; */
  align-items: center;

}

.secure-home-item-container {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 5px;
  text-decoration: none;
  color: black;
  width: 100%;
  position: relative;
}

.secure-home-item-container:hover {
  background-color: #e5e5e5;
}

.secure-home-item__active {
  background-color: #ededed;
}

.secure-home-item__active::before {
  content: '';
  position: absolute;
  left: 0;
  width: 4px;
  height: 80%;
  background-color: var(--primary-color);
  border-radius: 15px;
}

.secure-home-item-container > span {
  display: flex;
  /* font-size: 1rem; */
  font-weight: 400;
}

.secure-home-item-container > span > svg {
  width: 18px;
  height: 18px;
}

.secure-home-item-gorup {
  margin-top: 10px;
  border-top: 0.5px solid gray;
  display: flex;
  flex-direction: column;
}

.secure-home-item-gorup > .secure-home-group-title {
  display: flex;
  width: 100%;
  font-size: 12px;
  margin: 10px 0;
  color: gray;
  font-weight: 600;
}

.secure-home-content-container > .secure-home-page-container {
  display: flex;
  flex-grow: 1;
  padding: 10px;
}

.nested-secure-home-item-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}


.nested-secure-home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
}

.nested-secure-home-item-container .nested-dropdown-icon {
  margin-left: auto;
  display: flex;
  height: 100%;
  align-items: center;
}

.nested-secure-home-item-container .nested-dropdown-icon > svg {
  transition: all 200ms ease;
}

.nested-dropdown-icon__active > svg {
  transform: rotate(180deg)
}

.nested-dropdown-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50px;
  padding: 5px 10px;
  gap: 5px;
}


.secure-home-edit-btn {
  border-radius: 5px;
  padding: 10px 15px;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #B1092A;
  cursor: pointer;
  font-weight: 700;
  color: white;
  text-decoration: none;
  align-items: center;

}
  
.secure-home-edit-btn:disabled {
  cursor: not-allowed;
  background-color: gray;
}