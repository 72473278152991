.conversation-dropdown-horizontal{
    margin-top: 5px;
    flex-grow: 1;
}
.conversation-width-fill{
    width: 100%;
}

.conversation-dropdown-label {
    display: block;
    margin-bottom: 0.25rem;
    font-weight: 600;
}
.conversation-clause-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 50%;
    padding: 0.75rem;
    border-radius: 10px;
}

.conversation-clause-pre {
    font:inherit;
    white-space: pre-wrap;
    height: max-content;
}

.conversation-clause-view {
    flex-grow: 1;
    overflow: auto;
}

.conversation-element-shadow {
    box-shadow: 0px 5px 10px rgb(0,0,0,0.1);
}


.v3-legal-tab-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.v3-legal-pdfview-process {
    display: flex;
    flex-grow: 1;
    gap: 1rem;
}
.v3-legal-space-between {
    display: flex;
    justify-content: space-between;
}
.v3-legal-center {
    display: flex;
    justify-content: center;
}

.v3-legal-pre-wrap {
    white-space: pre-wrap;
    font: inherit;
}

.v3-legal-classification {
    display: block;
    font-weight: bold;
    font-size: 1.2rem;
}
.v3-legal-explanation {
    font-size: 0.9rem;
}
.v3-legal-panelView-message {
    font-size: 0.8rem;
}
.v3-legal-extractor-height {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* width: 1px; */
}
.v3-legal-extractor-container {
    display: flex;
    gap: 0.5rem;
    height: 1px;
    flex-direction: column;
    /* justify-content: center; */
    flex-grow: 1;
    overflow: auto;
}

.v3-legal-center-elements {
    text-align: center;
    color: lightgrey;
}
.v3-legal-floating-view-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.35rem;
}
.v3-legal-span-bold-label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.3rem;
}

.v3-legal-chat-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* height: 1px; */
}

.custom-markdown pre {
    white-space: pre-wrap; /* Enable wrapping of text */
    word-wrap: break-word; /* Break words that are too long */
    overflow-x: auto; /* Handle overflow with a horizontal scrollbar */
}
.custom-markdown code {
    white-space: inherit; /* Inherit the wrapping behavior from the parent */
}

.custom-markdown table {
    font-size: 0.8rem;
    border-collapse: collapse; /* Keep the collapse */
    width: 100%;
  }
  .custom-markdown th, .custom-markdown td {
      padding: 0.5rem;
      text-align: left;
      border: 1px solid #ddd; /* Add a border to all cells */
  }
  .custom-markdown th {
      background-color: #aaaaaa;
      color: white;
  }

  .custom-markdown table tr:first-child th {
    border-top: 0;
  }
  .custom-markdown table tr:last-child td {
    border-bottom: 0;
  }
  .custom-markdown table tr td:first-child,
  .custom-markdown table tr th:first-child {
    border-left: 0;
  }
  .custom-markdown table tr td:last-child,
  .custom-markdown table tr th:last-child {
    border-right: 0;
  }
  .custom-markdown ul, .custom-markdown ol {
    list-style-position: inside;
  }