.profile-settings-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.profile-label {
    margin-top: 10px;
    margin-bottom: 10px;
    color: red;
}

.user-img {
    height: 120px;
    width: 120px;
    margin-top: 5px;
}

.hr-line {
    margin-top: 10px;
}

.profile-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.edit-btn {
    background-color: #A6A6A6;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    border: none;
    border-radius: 2px;
    margin-right: 40px;
    cursor: pointer;
}