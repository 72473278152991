.custom-markdown-webChat table {
  font-size: 0.8rem;
  border-collapse: collapse;
  overflow: hidden;
  border: 1px solid #ddd; 
  width: 100%;
}
.custom-markdown-webChat th, .custom-markdown-webChat td {
padding: 0.5rem;
text-align: left;
border: 1px solid #ddd;
}
.custom-markdown-webChat th {
background-color: #aaaaaa;
color: white;
}
.custom-markdown-webChat table tr:first-child th {
border-top: 0;
}
.custom-markdown-webChat table tr:last-child td {
border-bottom: 0;
}
.custom-markdown-webChat table tr td:first-child,
.custom-markdown-webChat table tr th:first-child {
border-left: 0;
}
.custom-markdown-webChat table tr td:last-child,
.custom-markdown-webChat table tr th:last-child {
border-right: 0;
}
.custom-markdown-webChat ol {
list-style-type: decimal;
margin: 0 !important;
padding-left: 1.5rem;
display: flex;
flex-direction: column;
gap: 8px;
}
.custom-markdown-webChat ul {
margin: 0 !important;
list-style-type: disc;
padding-left: 1.5rem;
display: flex;
flex-direction: column;
gap: 8px;
}

.custom-markdown-webChat ul li, 
.custom-markdown-webChat ol li {
margin: 0 !important;
padding: 0 !important;
white-space: normal;
}

.custom-markdown-webChat ul li ul {
list-style-type: circle;
margin: 0 !important;
padding-left: 1.5rem;
display: flex;
flex-direction: column;
gap: 8px;
}

.custom-markdown-webChat ol li ol {
list-style-type: lower-alpha; 
margin: 0 !important;
padding-left: 1.5rem;
display: flex;
flex-direction: column;
gap: 8px;
}

.custom-markdown-webChat a {
color: #1a73e8;
word-break: break-all;
}

.custom-markdown-webChat a:hover {
text-decoration: underline;
}


