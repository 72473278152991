.admin-users-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.admin-users-header {
    display: flex;
    width: 100%;
    padding: 10px 0px;
    justify-content: space-between;
    align-items: center;
}

.admin-users-add-button {
    display: flex;
}