.speech-to-text-dashboard-container{
  display: flex;
  flex-grow: 1;
  z-index: 0;
  width: 100%;
}

.speech-to-text-dashboard-content{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 10px;
}

.speech-to-text-demo-insturctions {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 10px;
}

.demo-insturctions__title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--primary-color);
  border-radius: 30px;
  padding: 10px;
  color: #fff;
  text-align: center;
}

.demo-insturctions__text {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  gap: 20px;
}

.demo-insturctions__text > span {
  text-align: justify;
}

.speech-to-text-demo-setup {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  max-height: 600px;
  overflow: scroll;
  padding: 10px;
}

.speech-to-text-controls{
  display: flex;
  justify-content: center;
}

.speech-to-text-container{

  width: 100%;
  gap: 16px;
  margin-top: 10px;
  overflow: auto;
 
  }
.speech-to-text-component-container{
width: 100%;

}

.speech-to-text-component-textarea{
height: 200px;
}

.speech-to-text-demo-setup {
gap: 16px;
}

.speech-to-text-component-controls{
display:flex;
justify-content: flex-end;
}

.speech-to-text-demo-setup__button-container{
display: flex;
justify-content: flex-end;
width: 100%;
gap: 10px;
margin-top: 20px;
margin-bottom: 10px;
}

.stt-content-container{
  display: flex;
  padding: 16px;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin-top: 10px;
  justify-content: center;
}

.stt-input-container{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 16px;
}

.stt-upload-record-container{
  border: 1px solid gray;
  border-radius: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.stt-audio-files-container{
  border: 1px solid gray;
  border-radius: 4px;
  padding: 16px;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.stt-controls-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.stt-result-container{
  border: 1px solid gray;
  border-radius: 4px;
  padding: 16px;
  width: 100%;
  height: 100%;
}

.stt-result{

  border-radius: 4px;
  padding: 0px 16px 16px 16px;
  width: 100%;
  height: 100%;
}

.speech-to-text-container h1 {
  font-size: 45px;
  font-weight: 500;
  color: black;
  flex-direction: column;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 50px;
}

.speech-to-text-container span{
  color: #B1092A;
}

.stt-test-result-container{
  display: flex;
  align-items: center;
  margin-top: 5px;  
  justify-content: space-between;
}

.upload-icon {
  font-size: 30px; 
  height: 100%;
}

.delete-icon{
  font-size: 9px;

}

.stt-result-container textarea::placeholder {
  text-align: center; /* Center placeholder text */
  line-height: 140px; /* Reset line-height for placeholder */
  vertical-align: middle;
}

.stt-result-container textarea{
    width: 100%;
    height: 200px;
    margin-top: 20px;
    text-align: justify;
    /* line-height: 140px; */
    vertical-align: middle;
}

.speech-to-text-demo-dropdown-container{
  width: 100%;
  padding: 16px;
  }
  
  .speech-to-text-demo-dropdown{
  width: 100%;
  padding: 10px 8px;
  border-radius: 4px;
  margin-top: 10px;
  }

@media screen and (max-width: 768px) {
.speech-to-text-dashboard-container {
  padding: 10px;
}
}