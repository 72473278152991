.text-to-speech-dashboard-container{
  display: flex;
  flex-grow: 1;
  z-index: 0;
}

.text-to-speech-dashboard-content{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 10px;
}

.text-to-speech-label-container{
  margin-top: 16px;
  margin-bottom: 8px;
}

.text-to-speech-label{
  font-size: 16px;
  font-weight: 600;
}

.text-to-speech-select, .text-to-speech-textarea{
  background: #fafafa;
  border: 1px solid gray;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
}

.text-to-speech-demo-insturctions {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 10px;
}

.demo-insturctions__title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--primary-color);
  border-radius: 30px;
  padding: 10px;
  color: #fff;
  text-align: center;
}

.demo-insturctions__text {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  gap: 20px;
}

.demo-insturctions__text > span {
  text-align: justify;
}

.text-to-speech-demo-setup {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  padding: 16px;
  gap: 16px;

}

.tts-select{
  background: #e4e4e4;
  border: 1px solid gray;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 20px;
}

.text-to-speech-controls{
  display: flex;
  justify-content: center;
}

.text-to-speech-demo-dropdown-container, .text-to-speech-component-textarea-container{
max-width: 900px;
margin-top: 10px;
}

.text-to-speech-demo-dropdown, .text-to-speech-component-textarea{
width: 860px;
padding: 10px 8px;
border-radius: 4px;
margin-top: 5px;
}

.text-to-speech-component-container{
width: 100%;

}

.text-to-speech-component-textarea{
height: 200px;
}

.text-to-speech-demo-setup {
gap: 10px;
}

.text-to-speech-component-controls{
display:flex;
justify-content: flex-end;
}

.text-to-speech-demo-setup__button-container{
display: flex;
justify-content: flex-end;
max-width: 860px;
gap: 10px;
margin-top: 50px;
margin-bottom: 10px;
}

.tts-upload-container{
  width: 860px;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 16px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  justify-content: center;
}

.tts-input-container{
  display: flex;
  flex-direction: column;
  max-width: 900px;
  gap: 16px;
  height: max-content;
}



@media screen and (max-width: 768px) {
.text-to-speech-dashboard-container {
  padding: 10px;
}
}