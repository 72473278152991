

  .RAG-icon{
    margin-left: 5px;
    margin-bottom: 2px; 
  }

  .rag-typing{
    align-items: center;
    display: flex;
    justify-content: start;
    gap: 0.25rem;
  }
  .rag-typing .dot{
    border-radius: 9999px;
    height: 0.5rem;
    width: 0.5rem;
    animation: blink 1.5s infinite;
    opacity: 0;
  }

  .rag-typing .dot:nth-child(1) {
    animation-delay: 0.3333s;
  }
  .rag-typing .dot:nth-child(2) {
      animation-delay: 0.6666s;
  }
  .rag-typing .dot:nth-child(3) {
      animation-delay: 0.9999s;
  }
  @keyframes blink {
    50% {
      opacity: 1;
    }
  }
