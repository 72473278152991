.verify-email-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.verify-email-box {
    width: 100%;
    height: 100%;
    margin-top: 2rem;
    max-width: 700px;
    border: 0.5px solid rgb(232, 232, 232);
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.email-verification-container {
    display: flex;
    flex-direction: column;
    /* margin-top: auto; */
    padding: 1rem;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.email-verification-container > svg {
    margin-bottom: 3rem;
    stroke-width: 0.5;
}

.email-verification-container > .success-email-verified {
    /* width: 100%; */
    display: flex;
    padding: 1rem;
    border-radius: 5px;
    /* background-color: var(--primary-color); */
    /* color: #fff; */
}
.email-verification-container > .failed-email-verified {
    /* width: 100%; */
    display: flex;
    padding: 1rem;
    border-radius: 5px;
    /* background-color: var(--primary-color); */
    /* color: #fff; */

}